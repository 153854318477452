import DataService from "@/services/DataService";

export default {
  getAll() {
    return DataService.apiClient.get("/mitarbeiter");
  },

  get(id) {
    return DataService.apiClient.get("/mitarbeiter/" + id);
  },
  getAllPublic() {
    return DataService.apiClient.get("/mitarbeiter/public");
  },
  saveMitarbeiter(mitarbeiter) {
    if (mitarbeiter.id != null) {
      return DataService.apiClient.patch(
        "/mitarbeiter/edit/" + mitarbeiter.id,
        mitarbeiter.data
      );
    } else {
      return DataService.apiClient.post("/mitarbeiter/add", mitarbeiter.data);
    }
  },
  delete(id) {
    return DataService.apiClient.delete("/mitarbeiter/delete/" + id);
  },
};
