<template>
  <v-container>
    <v-col cols="12" sm="12" offset-sm="0">
      <v-card>
        <v-toolbar color="deep-purple lighten-2" dark flat>
          <v-toolbar-title>Mitarbeiter bearbeiten</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
            name="mitarbeiterform"
            @submit.prevent="save"
            v-model="formValid"
          >
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    label="Vorname"
                    name="firstname"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="firstname"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    label="Nachname"
                    name="lastname"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="lastname"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="8">
                  <v-text-field
                    label="Strasse"
                    name="street"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="street"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    label="Hausnummer"
                    name="streetnr"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="streetnr"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="3">
                  <v-text-field
                    label="Land"
                    name="country"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="country"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="3">
                  <v-text-field
                    label="PLZ"
                    name="plz"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="plz"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    label="Ort"
                    name="city"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="city"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12">
                  <v-text-field
                    label="Staatsbürgerschaft"
                    name="citizenship"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="citizenship"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    label="Email"
                    name="email"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="email"
                    required
                    :rules="emailRules"
                  />
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                  <v-text-field
                    label="Telefon"
                    name="phone"
                    type="text"
                    color="purple"
                    :clearable="true"
                    v-model="phone"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    v-model="holidays"
                    type="number"
                    label="Urlaubstage"
                    color="purple"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    v-model="svnr"
                    name="svnr"
                    type="number"
                    label="SV-Nr."
                    color="purple"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <v-text-field
                    v-model="mitarbeiterNr"
                    name="mitarbeiterNr"
                    type="number"
                    label="Mitarbeiter Nr."
                    color="purple"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="3">
                  <v-text-field
                    v-model="weekHours"
                    type="number"
                    label="Stunden / Woche"
                    color="purple"
                    max="40"
                    min="20"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="3">
                  <v-text-field
                    v-model="workDays"
                    type="number"
                    label="Arbeitstage / Woche"
                    color="purple"
                    max="7"
                    min="2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-menu
                    ref="coreStartTimeMenu"
                    v-model="coreStartTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="coreStartTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="coreStartTime"
                        label="Beginnzeit"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="coreStartTimeMenu"
                      v-model="coreStartTime"
                      full-width
                      format="24hr"
                      @click:minute="
                        changeBeginnZeit($refs.coreStartTimeMenu, coreStartTime)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-menu
                    ref="coreEndTimeMenu"
                    v-model="coreEndTimeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="coreEndTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="coreEndTime"
                        label="Endzeit"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="coreEndTimeMenu"
                      v-model="coreEndTime"
                      full-width
                      format="24hr"
                      @click:minute="
                        changeEndZeit($refs.coreEndTimeMenu, coreEndTime)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="6">
                  <div>Tägliche Arbeitszeit: {{ hoursPerDay }}</div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12">
                  <RFIDKeys />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :to="{ path: '/mitarbeiter' }"
            color="error"
            type="button"
            name="cancel"
            >Abbrechen</v-btn
          >
          <v-spacer />
          <v-btn
            @click="save"
            color="success"
            name="submit"
            type="submit"
            :disabled="!formValid"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import DataService from "@/services/MitarbeiterService.js";
import moment from "moment";

export default {
  name: "MitarbeiterForm",
  components: {},
  props: ["mitarbeiter"],
  data: () => ({
    firstname: "",
    lastname: "",
    street: "",
    streetnr: "",
    country: "",
    plz: "",
    city: "",
    citizenship: "",
    email: "",
    phone: "",
    weekHours: 38.5,
    workDays: 5,
    holidays: 0,
    svnr: "",
    mitarbeiterNr: "",
    coreStartTimeMenu: false,
    coreStartTime: null,
    coreEndTimeMenu: false,
    coreEndTime: null,
    id: null,
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid",
    ],
    formValid: false,
  }),

  methods: {
    save() {
      DataService.saveMitarbeiter({
        id: this.id,
        data: {
          firstname: this.firstname,
          lastname: this.lastname,
          street: this.street,
          streetnr: this.streetnr,
          country: this.country,
          plz: this.plz,
          city: this.city,
          citizenship: this.citizenship,
          email: this.email,
          phone: this.phone,
          weekHours: this.weekHours,
          workDays: this.workDays,
          coreStartTime: this.coreStartTime,
          coreEndTime: this.coreEndTime,
          holidays: this.holidays,
          svnr: this.svnr,
          mitarbeiterNr: this.mitarbeiterNr,
        },
      })
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "mitarbeiter" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Mitarbeiter hinzugefügt",
            text: "Mitarbeiter erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          //on error alert error
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message,
          });
        });
    },
    changeBeginnZeit(menu, time) {
      let startTime = time.split(":");
      let workTime = this.hoursPerDay.split(":");
      let mStart = moment.duration({
        hours: startTime[0],
        minutes: startTime[1],
      });

      let mWorkTime = moment.duration(
        "PT" + workTime[0] + "H" + workTime[1] + "M"
      );
      let endTime = mStart.add(mWorkTime);
      this.coreEndTime = endTime.hours() + ":" + endTime.minutes();
      menu.save(time);
    },
    changeEndZeit(menu, time) {
      menu.save(time);
    },
  },
  computed: {
    hoursPerDay() {
      let time = Number(parseFloat(this.weekHours) / this.workDays)
        .toPrecision(2)
        .split(".");
      let hour = time[0];
      let minutes = "0";
      if (time.length == 2) {
        minutes = (60 * time[1]) / 10;
      }

      return hour + ":" + minutes;
    },
  },
  created() {
    //console.log(this.mitarbeiter);
    if (this.mitarbeiter != null) {
      this.id = this.mitarbeiter._id;
      this.firstname = this.mitarbeiter.firstname;
      this.lastname = this.mitarbeiter.lastname;
      this.street = this.mitarbeiter.street;
      this.streetnr = this.mitarbeiter.streetnr;
      this.plz = this.mitarbeiter.plz;
      this.city = this.mitarbeiter.city;
      this.country = this.mitarbeiter.country;
      this.citizenship = this.mitarbeiter.citizenship;
      this.phone = this.mitarbeiter.phone;
      this.email = this.mitarbeiter.email;
      this.svnr = this.mitarbeiter.svnr;
      this.mitarbeiterNr = this.mitarbeiter.mitarbeiterNr;
      this.weekHours = this.mitarbeiter.weekHours;
      this.workDays = this.mitarbeiter.workDays;
      this.coreStartTime = this.mitarbeiter.coreStartTime;
      this.coreEndTime = this.mitarbeiter.coreEndTime;
      this.holidays = this.mitarbeiter.holidays;
    }
  },
};
</script>
